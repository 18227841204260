import React, { Fragment } from 'react';

import SEO from '@components/SEO';
import { Box, Grid } from '@mui/material';
import { RouteComponentProps } from '@reach/router';

const NotFoundPage: React.FC<RouteComponentProps> = () => (
	<>
		<SEO title="404" />
		<Box height="100%" display="flex" alignItems="center" justifyContent="center">
			<Grid container>
				<Grid item xs={12}>
					<h1>Sorry about this...</h1>
					<p>We couldn't find that page, maybe try something else.</p>
				</Grid>
			</Grid>
		</Box>
	</>
);

export default NotFoundPage;
